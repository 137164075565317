<template>
  <div class="re_box">
    <div style="background:#fff;box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);">
      <h1>
        <router-link  to="/"> <img alt="安全，万方安全" class="logo" src="@/assets/images/logo.png"></router-link>
        <span><i></i> 找回密码</span>
        <!-- <img class="logicon" src="@/assets/images/log.png">
        <div class="re_desc">
          <h4>万方商城中心全新升级</h4>
          <p>等保定级备案、等保合规建设，在线随时跟进进度。</p>
        </div>-->
      </h1> 
    </div>
  
	<div class="forget">
	<h3>找回密码</h3>
	<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"  class="demo-ruleForm">
		<!-- <el-form-item  prop="username">
      <el-popover
      placement="right"
      title=""
      width="220"
      trigger="click"
      >
      <ol>
        <li>1、2-10个字符，推荐使用中文；</li>
        <li>2、请勿包含银行卡/身份证信息，注册后不能修改；</li>
      </ol>
      <el-input  slot="reference" placeholder="请输入用户名" v-model="ruleForm.username"></el-input>
    </el-popover> -->
     <el-form-item prop="phone" >
       <el-input placeholder="请输入手机号码或邮箱" v-model="ruleForm.phone">
        <!-- <template slot="prepend">+86</template> -->
      </el-input>
    </el-form-item>

    
    <el-form-item prop="pass">
      <el-popover
      placement="right"
      title=""
      width="220"
      trigger="click"
      >
       <ol>
        <li>6-20个字符,密码不能与账号相同。</li>
      </ol>
      <el-input slot="reference" placeholder="设置密码" type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
    </el-popover>
    </el-form-item>
    <el-form-item  prop="checkPass">
      <el-popover
      placement="right"
      title=""
      width="220"
      trigger="click"
      >
       <ol>
        <li>请再次输入你的密码。</li>
      </ol>
      <el-input slot="reference" placeholder="请输入确认密码" type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
      </el-popover>
    </el-form-item>

   

    <el-form-item >
       <vue-simple-verify :width='350' @reset="reset" ref="verify" @success="success"/>
    </el-form-item >

  <!--   <el-form-item >
    <el-radio-group v-model="ruleForm.type" size="medium">
      <el-radio  :label="1">个人用户</el-radio>
      <el-radio  :label="2">企业用户</el-radio>
    </el-radio-group>
  </el-form-item> -->
   
    <!-- <el-checkbox v-model="checked">《万方商城服务条款》|《法律声明和隐私权政策》</el-checkbox> -->
	  <el-form-item>
	    <el-button class="btns" type="primary" @click="submitForm('ruleForm')">修改</el-button>
	    <el-button class="btns" @click="resetForm('ruleForm')">重置</el-button>
	  </el-form-item>
	</el-form>
  <div class="line"></div>
  <p class="haveaccotu">已有账号立即<router-link  to="/login">登录</router-link></p>
  
</div>


<el-dialog 
:close-on-click-modal="false" 
width="400px"
title="验证"
:visible.sync="dialogFormVisible"
>
  <el-form  :model="ruleForm" label-width="80px" >
    <el-form-item :label="isphone==1?'手机号码':'邮箱号码'">
     {{ruleForm.phone}}
    </el-form-item>
     <el-form-item label="验证码"  prop="smscode" class="code">
        <el-input v-model="ruleForm.smscode" style="width:120px;" placeholder="验证码"></el-input>
        <el-button type="primary" class="con_btn"  :disabled='isDisabled' @click="sendCode">{{buttonText}}</el-button>
      </el-form-item>
  </el-form> 
  <div slot="footer" class="dialog-footer">
    <el-button class="ph_btns" type="primary" @click="dialogFormVisible = false">提交</el-button>
  </div>
</el-dialog>


 <Footer/>
</div>
</template>


<script>
import Footer from "@/components/Footer.vue"
// import axios from "axios"
  export default {
    components:{
      Footer
    },
    data() {
    	var username = (rule, value, callback) => {
        // console.log(value.length)
        if (value === '') {
          callback(new Error('请输入账号'));
        }else if(value.length<2 || value.length>15){
           callback(new Error('账号名称长度在2到15'))
        } else {
          callback();
        }
      };
  
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        }
        else if(value.length<6 || value.length>20){
           callback(new Error('密码名称长度在6到20'))
        }else if(value==this.ruleForm.username){
           callback(new Error("密码不能与账号相同"))
        }else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
       var phone = (rule, value, callback) => {

        if (value === '') {
          callback(new Error('请输入手机号码或邮箱，不能为空'));
        } else if (value.length==11 && value.indexOf("@") == -1) {
          if (!(/^1[3|4|5|7|8]\d{9}$/.test(value))) {
            callback(new Error('手机号码格式错误!'));
          } else{
             this.isphone=1;
              callback();
          }
        }else {
          if ( value.indexOf("@") != -1) {
            if (!(/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(value))) {
              callback(new Error('邮箱格式错误!'));
            } else{
                this.isphone=0;
              callback();
            }
          }else{
             callback(new Error('格式错误!'));
          }
        }
      };
    // <!--验证码是否为空-->
        var checkSmscode = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入手机验证码'))
          } else {
            callback()
          }
        }

      return {
        ruleForm: {
          username:"",
          pass: '',
          checkPass: '',
          type:1,
          phone:'',
          smscode:'',
        },
        checked:false,
        feting:false,
        isphone:null,
        dialogFormVisible:false,
        buttonText: '发送验证码',
        isDisabled: false, // 是否禁止点击发送验证码按钮
        flag: true,
        rules: {
          username:[
           { validator: username, trigger: 'blur' }
          ],
          pass: [
            {validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur'}
          ],
          phone:[
           { validator: phone, trigger: 'blur' }
          ],
          smscode: [{ validator: checkSmscode, trigger: 'change' }],

        }
      };
    },
    mounted(){
      // this.changeCode()
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(!this.feting){
              alert('请滑动验证')
            }else{
               this.dialogFormVisible=true;
            }
            // alert('暂未开发!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
       // <!--发送验证码-->
      sendCode () {
        // console.log(1234)
        let tel = this.ruleForm.phone

          let time = 60
          this.buttonText = '已发送'
          this.isDisabled = true
          if (this.flag) {
            this.flag = false;
            let timer = setInterval(() => {
              time--;
              this.buttonText = time + ' 秒'
              if (time === 0) {
                clearInterval(timer);
                this.buttonText = '重新获取'
                this.isDisabled = false
                this.flag = true;
              }
            }, 1000)
          }

      },

      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.$refs.verify.reset();
      },
      success(val){
        this.feting=true;
        console.log('ok')
      },
     
   
      // changeCode(e) {
      //  e.target.src = `http://192.168.200.168:3030/api/captcha?${Math.random()}`;
      //  }

    }
  };
</script>
<style type="text/css" lang="less" scoped>
.re_box{
  position:relative;
  overflow: hidden;
  min-width: 1200px;

h1{
  width:1200px;
  margin:0 auto;
  font-size:20px;
  font-weight: normal;
  position:relative;
}
h1 span{
  font-size:20px;
  color:#181818;
  position: relative;
  top:5px;
}
h1 span i{
 width:1px;
 height:36px;
 background:#999;
 display: inline-block;
 position:relative;
 top:10px;
}
.logo{
  height:65px;
  padding:5px 0;
}
.forget{
	width:550px;
	position:relative;
    left:50%;
    margin-left:-275px;
	padding:50px 100px;
    padding-bottom:50px;
	margin-top:80px;
	margin-bottom:80px;
	background: #fff;
	box-shadow: 0 2px 15px rgba(0,0,0,.15);
	border-radius:5px;
  box-sizing: border-box;
}
h3{
    font-size:17px;
    text-align:center;
    font-weight: bold;
    padding-bottom:15px;
    margin-bottom:20px;
    border-bottom:1px solid #ddd;
}
.btns{
 	padding:12px 70px;
}
.logicon{
  position:absolute;
  top:290px;
  left:60px;
  width:450px;
}
.re_desc{
  position:absolute;
  left:30px;
  top:200px;
}
.re_desc h4{
   font-size:26px;
}
.re_desc p{
  font-size:18px;
  line-height: 38px;
  margin-top:15px;
  color:#999;
}
.el-checkbox{
  font-size:12px;
  margin-bottom:20px;
  margin-top:-15px;
}
.line{
  width:100%;
  height:1px;
  background:#ddd;
  margin-top:60px;
}
.haveaccotu{
  // float: right;
  position: relative;
  background:#fff;
  padding:0 10px;
  top:-15px;
  text-align: center;
  margin-top:8px;
  display: inline-block;
  left:35%;
}
.code .el-form-item__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.code .con_btn {
  margin-left:10px;
  width: 120px;
  text-align: center;
}
.el-dialog{
  width:400px;
}
.ph_btns{
  margin-right:200px;
  padding:12px 30px;
}



}




	
</style>



